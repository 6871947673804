import React, { Component } from "react";
import styled from "styled-components/macro";
import fileDownload from "js-file-download";

import UserContext from "../../context/user-context";
import BancoContext from "../../context/banco-context";
import { FloatingButtons } from "../FloatingButtons";
import FloatButton from "../FloatButton";
import SwitchButton from "../SwitchButton";

import { PillButton, PillContainer } from "../../theme/GlobalStyle";
// import Button from "../Button";

// import PowerbiEmbedded from "react-powerbi";

import { models, Report } from "powerbi-client";

import { PowerBIEmbed } from "powerbi-client-react";

import { ReactComponent as RefreshIcon } from "../../icons/refresh-icon.svg";
import { ReactComponent as DownloadIcon } from "../../icons/download-icon.svg";
import { ReactComponent as FullscreenIcon } from "../../icons/fullscreen-icon.svg";

import { store } from "react-notifications-component";
import "animate.css";
import {
  ToastNotification,
  notificationTopRight,
  notificationTopCenter,
} from "../ToastNotification";
import LoadingIcon from "../LoadingIcon";
import ArquivosService from "../../services/ArquivosService";
import PowerBIService from "../../services/PowerBiService";
import InlineMessage from "../@DesignSystem/InlineMessage";
import Title from "../@DesignSystem/Title";
import Button from "../@DesignSystem/Button";
import Modal from "../Modal";

const StyledH1 = styled.h1`
  font-size: 32px;
  text-align: center;
  margin-top: 32px;
`;

const DashboardContainer = styled.div`
  h2 {
    font-size: 2.5rem;
  }
`;

const LoadingDashboard = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #00000030;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 5rem;
  }
`;

const IframeContainer = styled.div`
  margin-top: 1em;
  position: relative;
  width: 95%;
  height: 0;
  padding-top: 56.25%;
  background: ${(props) => props.theme.pbiDashBackground};

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .switch {
    margin-right: 12rem;
    display: flex;
    align-items: center;

    @media (max-width: 1440px) {
      margin-right: 8rem;
    }

    @media (max-width: 1280px) {
      margin-right: 4rem;
    }

    p {
      font-size: 1.25rem;
      margin-right: 1rem;
      font-weight: 600;
      /* color: ${(props) => props.theme.primary}; */
    }
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  button {
    align-self: flex-end;
  }
`;

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pbiId: "",
      pbiUrl: "",
      pbiToken: "",
      pegouCredenciais: false,
      editableReport: false,
      downloading: false,
      semArquivosCarregados: true,
      isHome: true,
      templateSelecionado: "",
      templateSelecionadoNome: "",
      templates: [],
      reportConfig: {},
      dashboardLoading: true,
      ehEditavel: false,
      needRefresh: true,
      formatoSelecionado: "pbix",
      isModalOpen: false,
      avisoModal: true,
    };
  }

  //   getCredenciaisPbi = () => {
  //     PowerBiService.getCredenciaisPowerBI(this.props.tokenLogin)
  //       .then((resp) => //console.log(resp))
  //       .catch((err) => //console.log(err));
  //   }

  onEmbedded(embed) {
    // console.log(`Report embedded: `, embed, this);
  }

  componentDidMount() {
    //console.log(this.props.tokenLogin);

    const {
      params: { source },
    } = this.props.match;
    if (source === "nav") this.setState({ isHome: false });
    if (this.props.notHome) {
      if (window.Conpass) {
        // window.Conpass.startFlow("7UzFCRr_voM7t");
        // console.log("conpass dashboard");
      }
    } //conpass 2
    else {
      if (window.Conpass) {
        // window.Conpass.startFlow("Nl53Q2_y4Tdm");
        // console.log("conpass inicial");
      }
    } //conpass 1

    if (!this.props.temBanco) return;
    this.getTemplates();

    ArquivosService.getOcupacaoBanco(this.props.tokenLogin)
      .then((resp) => {
        if (resp.data.ocupacao > 0)
          this.setState({
            semArquivosCarregados: false,
          });
      })
      .catch((err) => {
        // console.log(err)
      });
    if (window.Conpass) {
      //console.log("Starta assistente");
      // Meu fluxo 3
      // window.Conpass.startFlow("vMKDiD9TPSwV7");
      // window.Conpass.startNps("I9Vm__5kfN-SG");
    }
  }

  getCredenciaisPowerBI(id = this.state.templateSelecionado) {
    this.setState({
      dashboardLoading: true,
    });
    PowerBIService.getEmbedTokenPowerBi(this.props.tokenLogin, id)
      .then((resp) => {
        this.setState({
          pbiId: resp.data.report.reportId,
          pbiUrl: resp.data.report.embedUrl,
          pbiToken: resp.data.report.token,
          pegouCredenciais: true,
          dashboardLoading: false,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  }
  shouldComponentUpdate(nextProps, nextState) {
    // //console.log(nextState);
    // //console.log(nextProps);
    // //console.log(this.state.pegouCredenciais);
    if (
      this.state.pegouCredenciais !== nextState.pegouCredenciais ||
      this.state.editableReport !== nextState.editableReport ||
      this.state.downloading !== nextState.downloading ||
      this.state.templateSelecionado !== nextState.templateSelecionado ||
      this.state.templates !== nextState.templates ||
      this.state.pbiId !== nextState.pbiId ||
      this.state.pbiUrl !== nextState.pbiUrl ||
      this.state.isModalOpen !== nextState.isModalOpen ||
      this.state.formatoSelecionado !== nextState.formatoSelecionado ||
      this.state.avisoModal !== nextState.avisoModal
    ) {
      return true;
    }
    return false;
  }

  refreshPbi = () => {
    if (this.props.temOperacao) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            Você não pode atualizar o dashboard agora porque já tem uma operação
            em andamento!{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopCenter,
      });
    } else {
      //console.log("refresh");
      store.addNotification({
        content: (
          <ToastNotification>Atualizando dashboard... </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopRight,
      });
      //console.log(this.props.tokenLogin);
      PowerBIService.refreshPowerBI(this.props.tokenLogin)
        .then((resp) => {
          // console.log("refresh" + resp);
          this.props.checkOperacao();
        })
        .catch((err) => {
          console.log(err);
          let msg = err.response.data.descricao;
          store.addNotification({
            content: (
              <ToastNotification bg="secondary">
                {msg ? msg : "Não foi possível atualizar o Dashboard"}
              </ToastNotification>
            ),
            // onRemoval: (id, removedBy) => //console.log(removedBy),
            ...notificationTopRight,
          });
        });
    }
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  toggleModalAviso = () => {
    this.setState((prevState) => ({
      avisoModal: !prevState.avisoModal,
    }));
  };

  dashFullscreen = () => {
    if (this.state.pegouCredenciais) {
      let iframe = document
        .querySelector(".report-style-class")
        .querySelector("iframe");
      if (iframe.requestFullscreen) {
        iframe.requestFullscreen();
      }
    }
  };

  downloadDashboard = () => {
    let erro;
    if (this.props.isTeste && this.state.formatoSelecionado === "pbix") {
      erro =
        "No período de teste é permitido baixar o dashboard apenas em PDF ou PowerPoint";
    }

    if (this.state.downloading) {
      erro = "O dashboard(.pbix) já está sendo baixado. Aguarde...";
    }
    if (erro) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            {erro}{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopRight,
      });
      return;
    }
    if (this.props.temOperacao) {
      store.addNotification({
        content: (
          <ToastNotification bg="secondary" textoAuxiliar="">
            Você não pode baixar o dashboard agora porque já tem uma operação em
            andamento!{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopRight,
      });
    } else {
      store.addNotification({
        content: (
          <ToastNotification textoAuxiliar="">
            Iniciando download do dashboard Aguarde...{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopRight,
      });

      this.setState({ downloading: true });

      let onDownload = (progressEvent) => {
        //  let progressoDownload = ((progressEvent.loaded/tamanho_arquivo) * 100).toFixed(0);
        // FAZ ALGUMA COISA COM ISSO?
        // console.log(progressoDownload);
      };
      PowerBIService.downloadDashboard(
        this.props.tokenLogin,
        onDownload,
        this.state.templateSelecionado,
        this.state.formatoSelecionado
      )
        .then((resp) => {
          //console.log(resp);
          fileDownload(resp.data, `dashboard.${this.state.formatoSelecionado}`);
          this.setState({ downloading: false });
          this.props.checkOperacao();
        })
        .catch(async (err) => {
          //console.log(err);
          this.setState({ downloading: false });
          let resObj = await err.response.data.text();
          let msg = "";
          try {
            msg = JSON.parse(resObj).descricao;
          } catch (e) {
            console.log(e);
          }
          store.addNotification({
            content: (
              <ToastNotification bg="danger">
                {msg
                  ? msg
                  : "Ocorreu um erro ao tentar fazer o download do .pbix"}{" "}
              </ToastNotification>
            ),
            // onRemoval: (id, removedBy) => //console.log(removedBy),
            ...notificationTopRight,
          });
        });
    }
  };

  toggleEditReport = () => {
    //console.log(this.state.editableReport);
    this.setState((prevState, prevProps) => ({
      editableReport: !prevState.editableReport,
    }));
    if (!this.state.editableReport)
      store.addNotification({
        content: (
          <ToastNotification bg="primary">
            Lembre-se de salvar as alterações no dashboard antes de sair da
            página ou sair do modo de edição{" "}
          </ToastNotification>
        ),
        // onRemoval: (id, removedBy) => //console.log(removedBy),
        ...notificationTopRight,
      });
  };

  getTemplates = () => {
    PowerBIService.getListaReports(this.props.tokenLogin)
      .then((resp) => {
        console.log(resp);
        let reports = resp.data.reports.map((rep) => {
          return {
            id: rep.nome,
            titulo: rep.nome.charAt(0).toUpperCase() + rep.nome.slice(1),
            nome: rep.nome,
            editavel: rep.editavel,
            atualizado: rep.atualizado,
          };
        });

        this.setState({
          templates: reports,
          templateSelecionado: reports[0].id,
          ehEditavel: reports[0].editavel,
          needRefresh: !reports[0].atualizado,
        });

        this.getCredenciaisPowerBI();
      })
      .catch((err) => {
        // console.log("err", err);
      });
    // this.setState({
    //   templates: [
    //     {
    //       id: 1,
    //       titulo: "Template 1",
    //     },
    //     {
    //       id: 2,
    //       titulo: "Template 2",
    //     },
    //     {
    //       id: 3,
    //       titulo: "Template 3",
    //     },
    //   ],
    // });
  };

  changeTemplate = (id, editavel, atualizado) => {
    // console.log(editavel);
    if (id === this.state.templateSelecionado) return;
    this.setState({
      templateSelecionado: id,
      ehEditavel: editavel,
      editableReport: false,
      needRefresh: !atualizado,
    });
    this.getCredenciaisPowerBI(id);
  };

  render() {
    let editableReportConfig = {
      type: "report", // Supported types: report, dashboard, tile, visual and qna
      id: this.state.pbiId,
      embedUrl: this.state.pbiUrl,
      accessToken: this.state.pbiToken,
      tokenType: models.TokenType.Embed,
      permissions: models.Permissions.All,
      viewMode: models.ViewMode.Edit,
      settings: {
        panes: {
          filters: {
            expanded: true,
            visible: true,
          },
        },
        // background: models.BackgroundType.Transparent,
      },
    };
    let nonEditableReportConfig = {
      type: "report", // Supported types: report, dashboard, tile, visual and qna
      id: this.state.pbiId,
      embedUrl: this.state.pbiUrl,
      accessToken: this.state.pbiToken,
      tokenType: models.TokenType.Embed,
      // permissions: models.Permissions.All,
      // viewMode: models.ViewMode.Edit,
      settings: {
        panes: {
          filters: {
            expanded: true,
            visible: true,
          },
        },
        // background: models.BackgroundType.Transparent,
      },
    };

    const formatos = [
      {
        value: "pbix",
        titulo: "PowerBI Desktop (.pbix)",
      },
      {
        value: "pdf",
        titulo: "PDF (.pdf)",
      },
      {
        value: "pptx",
        titulo: "PowerPoint (.pptx)",
      },
    ];

    return (
      <UserContext.Consumer>
        {(permissao) => (
          <BancoContext.Consumer>
            {(temBanco) =>
              !temBanco ? (
                <StyledH1>Você não tem banco vinculado à sua conta</StyledH1>
              ) : (
                <DashboardContainer>
                  {this.state.isModalOpen && (
                    <Modal
                      id="modalFormatoDash"
                      isOpen={this.state.isModalOpen}
                      onClose={this.toggleModal}
                      modalSize="lg"
                      modalTipo="primary"
                      pgBackground="primary"
                    >
                      <ModalContainer>
                        <Title headingLevel="h5">
                          Escolha um formato para baixar o dashboard:
                        </Title>
                        <PillContainer>
                          {formatos.map((formato, index) => (
                            <PillButton
                              key={index}
                              active={
                                this.state.formatoSelecionado === formato.value
                              }
                              onClick={() => {
                                this.setState({
                                  formatoSelecionado: formato.value,
                                });
                              }}
                            >
                              {formato.titulo}
                            </PillButton>
                          ))}
                        </PillContainer>
                        <Button onClick={this.downloadDashboard}>
                          Baixar {this.state.formatoSelecionado}
                        </Button>
                      </ModalContainer>
                    </Modal>
                  )}
                  <Header>
                    <h2>PowerBI Dashboards</h2>

                    {!this.props.isLeitor && this.state.ehEditavel && (
                      <div className="switch">
                        <p>Dashboard editável</p>
                        <SwitchButton
                          id="editable-report"
                          onChange={this.toggleEditReport}
                          checked={this.state.editableReport}
                          small
                        />
                      </div>
                    )}
                  </Header>
                  {this.state.templates.length > 1 && (
                    <PillContainer>
                      {this.state.templates.map(
                        ({ id, titulo, editavel, atualizado }) => (
                          <PillButton
                            key={id}
                            active={this.state.templateSelecionado === id}
                            onClick={() =>
                              this.changeTemplate(id, editavel, atualizado)
                            }
                          >
                            {titulo === "Branco" ? "Personalizado" : titulo}
                          </PillButton>
                        )
                      )}
                    </PillContainer>
                  )}
                  {/* Implementacao nao oficial */}

                  {/* <IframeContainer>
          {this.state.pegouCredenciais && (
            <PowerbiEmbedded
              ref={this.dashRef}
              id={this.state.pbiId} // required
              embedUrl={this.state.pbiUrl} // required
              accessToken={this.state.pbiToken} // required
              navContentPaneEnabled={true}
            />
          )}
        </IframeContainer> */}
                  {/* {this.state.needRefresh ? (
                    <InlineMessage type="system" iconColor="laranja">
                      É necessário atualizar o dashboard para sincronizar os
                      dados dos últimos arquivos carregados
                    </InlineMessage>
                  ) : (
                    <InlineMessage type="success">
                      O dashboard já está sincronizado com os últimos arquivos
                      carregados
                    </InlineMessage>
                  )} */}
                  <IframeContainer className="pbi-iframe">
                    {this.state.pegouCredenciais &&
                      (this.state.editableReport ? (
                        <PowerBIEmbed
                          ref={this.dashRef}
                          key="editable"
                          id="editabledash"
                          embedConfig={{
                            type: "report", // Supported types: report, dashboard, tile, visual and qna
                            id: this.state.pbiId,
                            embedUrl: this.state.pbiUrl,
                            accessToken: this.state.pbiToken,
                            tokenType: models.TokenType.Embed,
                            permissions: models.Permissions.All,
                            viewMode: models.ViewMode.Edit,
                            settings: {
                              panes: {
                                filters: {
                                  expanded: true,
                                  visible: true,
                                },
                              },
                              // background: models.BackgroundType.Transparent,
                            },
                          }}
                          eventHandlers={
                            new Map([
                              [
                                "loaded",
                                function () {
                                  console.log("Report loaded");
                                },
                              ],
                              [
                                "rendered",
                                function () {
                                  console.log("Report rendered");
                                },
                              ],
                              [
                                "error",
                                function (event) {
                                  // console.log(event.detail);
                                },
                              ],
                            ])
                          }
                          cssClassName={"report-style-class"}
                          getEmbeddedComponent={(embeddedReport) => {
                            this.report = embeddedReport; //as Report
                          }}
                        />
                      ) : (
                        <PowerBIEmbed
                          id="noneditabledash"
                          key="noneditable"
                          ref={this.dashRef}
                          embedConfig={{
                            type: "report", // Supported types: report, dashboard, tile, visual and qna
                            id: this.state.pbiId,
                            embedUrl: this.state.pbiUrl,
                            accessToken: this.state.pbiToken,
                            tokenType: models.TokenType.Embed,
                            // permissions: models.Permissions.All,
                            // viewMode: models.ViewMode.Edit,
                            settings: {
                              panes: {
                                filters: {
                                  expanded: true,
                                  visible: true,
                                },
                              },
                              // background: models.BackgroundType.Transparent,
                            },
                          }}
                          eventHandlers={
                            new Map([
                              [
                                "loaded",
                                function () {
                                  console.log("Report loaded");
                                },
                              ],
                              [
                                "rendered",
                                function () {
                                  console.log("Report rendered");
                                },
                              ],
                              [
                                "error",
                                function (event) {
                                  // console.log(event.detail);
                                },
                              ],
                            ])
                          }
                          cssClassName={"report-style-class"}
                          getEmbeddedComponent={(embeddedReport) => {
                            this.report = embeddedReport; //as Report
                          }}
                        />
                      ))}
                    {this.state.dashboardLoading && (
                      <LoadingDashboard>
                        <LoadingIcon fill="white" />
                      </LoadingDashboard>
                    )}
                  </IframeContainer>
                  {/* <Button background="primary" onClick={this.toggleEditReport}>
          Editar Dashboard
        </Button> */}
                  <FloatingButtons>
                    {!this.props.isLeitor && (
                      <FloatButton
                        background="primary"
                        big
                        texto="Atualizar dashboard"
                        onClick={this.refreshPbi}
                        new={false}
                      >
                        <RefreshIcon className="icon" />
                      </FloatButton>
                    )}
                    <FloatButton
                      white
                      background={this.state.downloading ? "gray" : "primary"}
                      texto={
                        this.state.downloading
                          ? "Baixando dashboard"
                          : "Baixar dashboard"
                      }
                      onClick={this.toggleModal}
                    >
                      {this.state.downloading ? (
                        <LoadingIcon big />
                      ) : (
                        <DownloadIcon className="icon" />
                      )}
                    </FloatButton>
                    <FloatButton
                      white
                      background="primary"
                      texto="Ver dashboard em tela cheia"
                      onClick={this.dashFullscreen}
                    >
                      <FullscreenIcon className="icon" />
                    </FloatButton>
                  </FloatingButtons>
                </DashboardContainer>
              )
            }
          </BancoContext.Consumer>
        )}
      </UserContext.Consumer>
    );
  }
}
